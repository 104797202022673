<template>
  <div class="page">
    <div class="data-table-container">
      <div class="tools-bar">
        <el-button-group>
          <el-button type="primary" @click="$router.push({name: 'goods-editor'})">新增</el-button>
          <el-button @click="load">刷新</el-button>
          <el-select class="i" v-model="filter_status" style="width: 140px; margin-left: 10px">
            <el-option v-for="c in ['全部',  '正常', '隐藏', '已下架']" :key="c" :label="c" :value="c"/>
          </el-select>
        </el-button-group>
        <div class="grow"></div>
        <table-search-simple :table-data="tableData" v-model="tableData_show" :fields="['title']"/>
      </div>

      <el-table class="table"
                v-loading="loading"
                :data="tableData_show"
                element-loading-text="获取数据中 ..."
                @row-click="onClickRow"
                stripe
                highlight-current-row>
        <el-table-column align="center" label="ID" width="95" prop="id" sortable/>
        <el-table-column label="类型" prop="sell_type" width="50"/>
        <el-table-column label="状态" width="70" prop="status"/>
        <el-table-column label="商品" prop="title"/>
        <el-table-column align="center" label="排序" width="80" prop="i_order" sortable/>
        <el-table-column label="浏览量" width="90" prop="stat_visit" sortable/>
        <el-table-column label="销量" width="80" prop="stat_sold" sortable/>
        <el-table-column label="创建时间" width="160" prop="datetime_created" sortable/>
      </el-table>

      <el-pagination class="pagination"
                     layout="total, prev, pager, next"
                     :current-page="page"
                     :page-size="30"
                     :total="total"/>

    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData_show: [],
      tableData: [],
      page: 1,
      total: 1,
      loading: false,
      filter_status: '全部',
    }
  },
  mounted() {
    this.load()
  },
  watch: {
    filter_status(v) {
      if (v === '全部') {
        this.tableData_show = this.tableData
      } else {
        this.tableData_show = this.tableData.filter(r => r.status === v)

      }

    }
  },
  methods: {
    load() {
      this.loading = true
      this.$api.request('/goods/list_simple', {
        sort_field: 'id', show_all: true
      }).then(data => {
        this.loading = false
        this.tableData = data
        this.tableData_show = data
      })
    },
    onClickRow({id}) {
      this.$router.push({name: 'goods-editor', query: {id}})
    }
  }
}
</script>
